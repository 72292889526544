import React from "react";
import { INNER_CHOOSE_IMG } from "../../lib/assets";

export const ChooseAreaExpats = () => {
  return (
    <section id="context" className="choose-area-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="choose-content-three">
              <div className="section-title-two white-title mb-30">
                <span className="sub-title">Contexual FAQs</span>
                <h2 className="title">
                  Additional Terminology & Requirements Information
                </h2>
              </div>
              <p>
                The Nomadic Platform requires contextual information regarding your personal status, business type and income level to function.
              </p>

              <div className="accordion-wrap-two">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What exactly is a "Global Expat"?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for Global Expats, generally this refers to an individual living outside their country of nationality. It may include - but is not limited to - the following:<br/>
                          <br/>
                          • Overseas Permanent Residents<br/>
                          • Overseas Temporary Residents<br/>
                          • Overseas Tax Residents (Any)<br/>
                          <br/>
                          If you’re a U.S. Citizen, you’ll need to use our U.S. "Persons" version of the platform, regardless of your residency status. Certain other nationalities (including those of countries are sanctioned) may not be able to use Nomadic Tax, or face restrictions in using it. Please contact us for more information.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Who can use the Nomadic platform?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for individuals and businesses earning from an *active* trade. If your main source of income is passive investments, please get in touch as you won't be able to use the platform and we can provide alternative solutions.
                          <br/><br/>
                          • Freelancers<br/>
                          • Digital Nomads<br/>
                          • Remote Workers<br/>
                          • Entrepreneurs<br/>
                          • Content Creators<br/>
                          • Business Owners<br/>
                          • Ticket Brokers<br/>
                          • Video Streamers<br/>
                          • Ecommerce Stores<br/>
                          • SaaS Businesses<br/>
                          • Active Investors
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How much do I need to be making?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for profits of $65,000 or greater, this could either be through self employment or a legal business structure. Unfortunately we can't help if you're making less than that, but if you're making more our specialized team can help on a case-by-case basis.Certain residency situations may have limitations to the profit thresholds for Nomadic Tax. 
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Which residencies are eligible?
                        
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Whilst Nomadic is designed for U.S. "Persons" and U.K. Tax Residents, if you're not one, Nomadic may still work for you depending on your tax residency, individual circumstances and (in some cases) your nationality. Please get in touch for clarification on your situation and to see if Nomadic Tax can work for you. Our expat and nomad version is coming soon.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="choose-img-three">
              <img src={INNER_CHOOSE_IMG} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
