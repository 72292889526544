import React from "react";
import { Layout } from "../../layouts/Layout";
import { SHARE, TEAM_DETAILS_IMG, TEAM_IMG02 } from "../../lib/assets";
import { ContactAreaOne } from "../../components/ContactAreas/ContactAreaOne";
import { TestimonialAreaFour } from "../../components/TestimonialAreas/TestimonialAreaFour";

const BusinessOwners = () => {
  return (
    <Layout header={4} footer={1} breadcrumb={"Use Cases"} title={"Business Owners"}>
      {/* team-details-area */}
      <section className="team-details-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="team-details-info-wrap">
                <div className="team-details-thumb">
                  <img src={TEAM_IMG02} width="460px" alt="Business Owners" />
                </div>

                <div className="team-details-info">
                  <div className="td-info-bottom">
                    <a href="." className="share-btn">
                      Learn More
                    </a>
                    <a href="#contact" className="btn btn-three">
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="team-details-content">
                <h2 className="title">Business Owners</h2>
                <span></span>
                <p>
                  Small business owners in fields like consulting, SaaS, e-commerce, and digital services can leverage our platform to streamline client payments and optimize tax liability. Whether you’re running an online store or offering subscription-based software, we provide the tools to enhance your financial operations and profitability.
                </p>
                <div className="team-skill-wrap">
                  <h3 className="title-two">Potential Tax Savings</h3>
                  <p>
                  </p>
                  <div className="progress-wrap">
                    <div className="progress-item">
                      <h6 className="title">U.S. "Persons"</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow="65"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay=".1s"
                          style={{
                            width: "90%",
                          }}
                        >
                          <span>90%</span>
                        </div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <h6 className="title">U.K. Residents</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay=".2s"
                          style={{
                            width: "93%",
                          }}
                        >
                          <span>93%</span>
                        </div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <h6 className="title">Expats & Nomads</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow="90"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay=".3s"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span>100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <TestimonialAreaFour />
      <ContactAreaOne />
    </Layout>
  );
};

export default BusinessOwners;
