import React from "react";
import { Layout } from "../../layouts/Layout";
import { SHARE, TEAM_DETAILS_IMG, TEAM_IMG01 } from "../../lib/assets";
import { ContactAreaOne } from "../../components/ContactAreas/ContactAreaOne";
import { TestimonialAreaFour } from "../../components/TestimonialAreas/TestimonialAreaFour";

const ContentCreators = () => {
  return (
    <Layout header={4} footer={1} breadcrumb={"Use Cases"} title={"Content Creators"}>
      {/* team-details-area */}
      <section className="team-details-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="team-details-info-wrap">
                <div className="team-details-thumb">
                  <img src={TEAM_IMG01} width="460px" alt="Content Creators" />
                </div>

                <div className="team-details-info">
                  <div className="td-info-bottom">
                    <a href="." className="share-btn">
                      Learn More
                    </a>
                    <a href="#contact" className="btn btn-three">
                      Get Started Now
                    </a>
                  </div>
                </div>
                
                <div className="team-skill-wrap">
                  <h3 className="title-two">Potential Tax Savings</h3>
                  <p>
                  </p>
                  <div className="progress-wrap">
                    <div className="progress-item">
                      <h6 className="title">U.S. "Persons"</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow="65"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay=".1s"
                          style={{
                            width: "90%",
                          }}
                        >
                          <span>90%</span>
                        </div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <h6 className="title">U.K. Residents</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay=".2s"
                          style={{
                            width: "93%",
                          }}
                        >
                          <span>93%</span>
                        </div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <h6 className="title">Expats & Nomads</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow="90"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay=".3s"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span>100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="team-details-content">
                <h2 className="title">Content Creators</h2>
                <span></span>
                <p>
                  Creators generating income from platforms like YouTube, TikTok, Instagram, or through online courses, sponsorships, and merchandise sales can simplify revenue collection with our platform. By managing payouts and offering tailored tax strategies, we help you focus on your creative work while maximizing your financial efficiency.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <TestimonialAreaFour />
      <ContactAreaOne />
    </Layout>
  );
};

export default ContentCreators;
