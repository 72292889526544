import { AboutFive } from "../../components/About/AboutFive";
import { AboutUK } from "../../components/About/AboutUK";
import { BannerNomads } from "../../components/Banners/BannerNomads";
import { BlogAreaFour } from "../../components/BlogAreas/BlogAreaFour";
import { BrandFour } from "../../components/Brand/BrandFour";
import { ChooseAreaTwo } from "../../components/ChooseAreas/ChooseAreaTwo";
import { CtaAreaUK } from "../../components/CtaAreas/CtaAreaUK";
import { EstimateAreaOne } from "../../components/EstimateAreas/EstimateAreaOne";
import { FeatureFour } from "../../components/Features/FeatureFour";
import { PricingAreaUK } from "../../components/PricingAreas/PricingAreaUK";
import { ServicesThree } from "../../components/Services/ServicesThree";
import { ServiceOne } from "../../components/Services/ServiceOne";
import { TeamAreaFour } from "../../components/TeamAreas/TeamAreaFour";
import { TeamAreaTwo } from "../../components/TeamAreas/TeamAreaTwo";
import { ContactAreaOne } from "../../components/ContactAreas/ContactAreaOne";
import { ChooseAreaUK } from "../../components/ChooseAreas/ChooseAreaUK";
import { CounterAreaOne } from "../../components/CounterAreas/CounterAreaOne";
import { FeatureUK } from "../../components/Features/FeatureUK";
import { FaqAreaOne } from "../../components/FaqAreas/FaqAreaOne";
import { ServicesTwo } from "../../components/Services/ServicesTwo";
import { CtaAreaTwo } from "../../components/CtaAreas/CtaAreaTwo";
import { TestimonialAreaFour } from "../../components/TestimonialAreas/TestimonialAreaFour";
import { RequestAreaTwo } from "../../components/RequestAreas/RequestAreaTwo";
import { Layout } from "../../layouts/Layout";

const HomeNomads = () => {
  return (
    <Layout header={4} footer={1}>
      {/* banner-area */}
      <BannerNomads />

      <FeatureFour />
      <AboutUK />
      {/* <AboutFive /> */}

      {/* services-area */}
      {/* <ServiceOne /> */}
      
      {/* brand-area */}
      {/* <BrandFour /> */}

      {/* choose-area */}
      <ChooseAreaTwo />
      
      {/* cta-area */}
      <CtaAreaUK />
        
      {/* team-area */}
      <TeamAreaTwo />
      
      {/* request-area  */}
      <RequestAreaTwo />
      
      {/* cta-area */}
      {/* <CtaAreaThree /> */}
      
      {/* estimate-area */}
      <EstimateAreaOne />

      {/* testimonial-area */}
      <TestimonialAreaFour />
      
      {/* features-area */}
      <FeatureUK />

      {/* pricing-area */}
      <PricingAreaUK />
      
      
      {/* services-area */}
      <ChooseAreaUK />
      
      {/* faq-area */}
      {/* <FaqAreaOne /> */}
      
      {/* <ServicesTwo /> */}
      
      {/* contact-area */}
      <ContactAreaOne />
      
    </Layout>
  );
};

export default HomeNomads;
